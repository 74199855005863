import type { PopulatedMenu, PopulatedSection } from 'root/apiTypes';
import type { CheckboxElement, MenuCheckbox } from 'root/clientTypes';

export const isSectionChecked = (menusCheckboxes: MenuCheckbox[], sectionId: string): boolean => {
  for (const menu of menusCheckboxes) {
    const section = menu.sections.find((sec) => sec.id === sectionId);
    if (section) {
      return section.checked;
    }
  }
  return false;
};

export const isPriceShowed = ({
  price,
  showPrice,
  showZeroPrice,
  sectionChecked,
}: {
  price: string;
  showPrice: boolean;
  showZeroPrice: boolean;
  sectionChecked: boolean;
}): boolean => {
  const priceIsZero = Number(price) === 0;

  if (!showPrice) {
    return false;
  }

  if (priceIsZero) {
    return showZeroPrice ? sectionChecked : false;
  }

  return true;
};

export const getUpdatedMenu = ({
  menusCheckboxes,
  menuId,
  sectionId,
  checked,
}: {
  menusCheckboxes: MenuCheckbox[];
  menuId?: string;
  sectionId?: string;
  checked?: boolean;
}) => {
  return menusCheckboxes.map((menu) => {
    if (!menuId || menu.id === menuId) {
      const updatedSections = menu.sections.map((section) =>
        sectionId && section.id === sectionId
          ? { ...section, checked: checked ?? true }
          : { ...section, checked: sectionId ? section.checked : checked ?? true }
      );

      const areAllSectionsUnchecked = updatedSections.every((section) => !section.checked);

      return {
        ...menu,
        sections: updatedSections,
        checked: sectionId ? !areAllSectionsUnchecked : checked ?? true,
      };
    }
    return menu;
  });
};

export const getInitialMenusCheckboxes = (menus: PopulatedMenu[] = []): MenuCheckbox[] => {
  return menus.map(
    (menu) =>
      ({
        id: menu.id,
        name: menu.name,
        checked: true,
        sections: menu.sections.map((section) => ({ id: section.id, name: section.name, checked: true })),
      } as MenuCheckbox)
  );
};

const getUpdatedSections = (menu: PopulatedMenu, existingMenu: MenuCheckbox): CheckboxElement[] => {
  return menu.sections.map((section) => {
    const existingSection = existingMenu.sections?.find(
      (existingSec: PopulatedSection) => existingSec.id === section.id
    );
    return {
      id: section.id!,
      name: section.name!,
      checked: existingSection?.checked ?? true,
    };
  });
};

export const getUpdatedMenuCheckboxes = (
  populatedMenus: PopulatedMenu[],
  existingMenusCheckbox: MenuCheckbox[]
): MenuCheckbox[] => {
  return populatedMenus.map((menu) => {
    const existingMenu = existingMenusCheckbox.find((checkboxMenu: MenuCheckbox) => checkboxMenu.id === menu.id);
    if (existingMenu) {
      return {
        id: menu.id!,
        name: menu.name!,
        checked: existingMenu.checked ?? true,
        sections: getUpdatedSections(menu, existingMenu),
      };
    } else {
      return {
        id: menu.id!,
        name: menu.name!,
        checked: true,
        sections: menu.sections.map((section) => ({
          id: section.id!,
          name: section.name!,
          checked: true,
        })),
      };
    }
  });
};
